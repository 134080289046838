<!doctype html>
<html lang="en">
  <head>
    <script type="module">
      import RefreshRuntime from "/@react-refresh"
      RefreshRuntime.injectIntoGlobalHook(window)
      window.$RefreshReg$ = () => {}
      window.$RefreshSig$ = () => (type) => type
      window.__vite_plugin_react_preamble_installed__ = true
    </script>

    <script type="module" src="/@vite/client"></script>

    <meta charset="UTF-8" />
    <link rel="icon" type="image/svg+xml" href="/vite.svg" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

    <!-- Title -->
    <title>Idevo, échangez vos compétences contre des parts</title>

    <!-- SEO Metadata -->
    <meta name="description" content="Idevo : Une plateforme unique pour échanger vos compétences contre des parts d'entreprise. Découvrez un moyen innovant de collaborer et de construire ensemble." />
    <meta name="keywords" content="Idevo, compétences, parts d'entreprise, collaboration, échange de compétences, entrepreneuriat, start-up, projets collaboratifs" />
    <meta name="author" content="Idevo Team" />
    <meta name="robots" content="index, follow" />

    <!-- Open Graph Metadata -->
    <meta property="og:title" content="Idevo, échangez vos compétences contre des parts" />
    <meta property="og:description" content="Rejoignez Idevo, la plateforme qui révolutionne la collaboration en permettant d'échanger vos compétences contre des parts d'entreprise." />
    <meta property="og:image" content="/vite.svg" />
    <meta property="og:url" content="https://www.idevo.com" />
    <meta property="og:type" content="website" />

    <!-- Twitter Card Metadata -->
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Idevo, échangez vos compétences contre des parts" />
    <meta name="twitter:description" content="Rejoignez Idevo, la plateforme qui révolutionne la collaboration en permettant d'échanger vos compétences contre des parts d'entreprise." />
    <meta name="twitter:image" content="/vite.svg" />

  </head>
  <body>
    <div id="root"></div>
    <script type="module" src="/src/main.tsx"></script>
  </body>
</html>
